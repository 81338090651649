
import Vue from 'vue'
import { mapState } from 'vuex'
import LazyHydrate from 'vue-lazy-hydration'
import global from '~/mixins.js/global'
import { addTrailingSlash } from '@/utils/helpers'
import analytics from '~/mixins.js/analytics'
//import webinarKit from '~/utils/plugins/webinarkit'

Vue.mixin(global)

// Vue.use(webinarKit)

export default {
  mixins: [analytics],
  components: {
    LazyHydrate,
    'id-header': () => import('~/components/lazy/Header.vue'),
    'id-footer': () => import('~/components/lazy/Footer.vue'),
    'id-exit-intend-overlay': () => import('~/components/ExitIntendOverlay.vue'),
    'id-modals-added-to-cart': () => import('~/components/modals/AddedToCart.vue'),
    'id-lazy-cookie-alert': () => import('~/components/lazy/CookieAlert.vue'),
    'id-lazy-chat': () => import('~/components/lazy/Chat.vue'),
    'id-structured-data-lazy-search-action': () => import('~/components/structured-data/lazy/SearchAction.vue')
  },
  head () {
    const canonicalUrl = addTrailingSlash(`${this.$config.baseURL}${this.$route.path}/`)
    return {
      link: [
        {
          rel: 'canonical',
          href: canonicalUrl
        }
      ],
      meta: [
        {
          hid: 'copyright',
          name: 'copyright',
          content: 'infoDiagram Ltd'
        },
        {
          hid: 'og:site_name',
          property: 'og:site_name',
          content: 'InfoDiagram.com'
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$config.baseURL
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: 'Professional PowerPoint slides: 4600+ business graphics, 6000+ icons, expert templates. Creative presentation graphics, simple visualization of business concepts by diagrams and icons. PowerPoint, Keynote, Google Slides editable.'
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: 'Stunning business presentations in no time. Powerful business slides and templates for powerpoint.'
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website'
        },
        {
          hid: 'og:locale',
          property: 'og:locale',
          content: 'en_US'
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: `${this.$config.baseURL}/infodiagram_og.jpeg`
        }
      ]
    }
  },
  computed: {
    ...mapState('checkout', ['modals']),
    ...mapState('exitOverlay', ['showExitOverlay', 'overlayDisplayed']),
    onCheckoutPage () {
      return /checkout/.test(this.$route.name)
    },
    onFreeSamplePage () {
      return this.$route.params.diagram && this.$route.params.diagram.startsWith('free-sample')
    },
    onContactPage () {
      return this.$route.name === 'contact'
    }
  },
  methods: {
    leaveFromTop (e) {
      if (!this.overlayDisplayed && !this.onCheckoutPage && !this.onFreeSamplePage) {
        if (e.clientY < 30) {
          this.showOverlay()
        }
      }
    },
    trackFocus () {
      if (!this.overlayDisplayed) {
        setTimeout(() => {
          document.addEventListener('mousemove', this.leaveFromTop, false)
        }, 12000)
      }
    },
    showOverlay () {
      document.querySelector('body').classList.add('block-scroll')
      this.$store.commit('exitOverlay/setOverlayDisplayed')
      this.$store.commit('exitOverlay/setShowExitOverlay', true)
      this.trackEvent('Exit intent', 'Show')
      document.removeEventListener('mousemove', this.leaveFromTop, false)
    },
    dropdownBlurHandler () {
      document.addEventListener('click', function (e) {
        if (!e.target.classList.contains('dropdown-toggle')) {
          let openDropdowns = document.querySelectorAll('.dropdown.show')
          if (openDropdowns.length > 0) {
            openDropdowns.forEach(elem => elem.children[0].click())
          }
        }
      }, false)
    }
  },
  mounted () {
    this.trackFocus()
    this.dropdownBlurHandler()
  }
}
